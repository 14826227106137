<template>
  <ul class="menu-list">
    <li>
      <nuxt-link
        to="/"
        active-class="tw-text-white"
        exact
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <BlocksIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Dashboard</span>
      </nuxt-link>
    </li>
    <li class="tw-text-manatee">
      <nuxt-link
        to="/messages"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <MessageIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Messages</span>
      </nuxt-link>
    </li>
    <li class="tw-text-manatee">
      <nuxt-link
        to="/news"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <NotificationIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">News</span>
      </nuxt-link>
    </li>
    <li class="tw-text-manatee">
      <nuxt-link
        v-if="$can('list', 'Operator')"
        to="/my-team"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <GroupIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">My Team</span>
      </nuxt-link>
    </li>
    <li class="tw-text-manatee">
      <a
        class="has-dropdown tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center tw-justify-between"
        @click="toggleSubmenu('logs')"
      >
        <div class="tw-flex tw-items-center">
          <FileThunderIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">Logs</span>
        </div>
        <b-icon icon="chevron-down" size="is-small"></b-icon>
      </a>
      <ul v-show="displayedSubmenus.logs">
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/journal-edits"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Journal Edits
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/lock-logs"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Lock Logs
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/pay-rate"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Pay Rate
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/payment-updates"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Payment Updates
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/removed-accounts"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Removed Accounts
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/added-accounts"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Added Accounts
          </nuxt-link>
        </li>
      </ul>
    </li>
    <li class="tw-text-manatee">
      <nuxt-link
        to="/faqs"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center tw-relative"
      >
        <div class="tw-flex tw-items-center tw-relative">
          <QuestionIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">FAQs</span>
          <div
            v-if="faqViews.length"
            class="tw-flex tw-absolute tw-h-3 tw-w-3 tw-top-0 tw-left-0"
          >
            <span
              class="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400  tw-opacity-50"
            ></span>
            <span
              class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-600"
            ></span>
          </div>
        </div>
      </nuxt-link>
    </li>
    <li class="tw-text-manatee">
      <nuxt-link
        to="/bonus"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <StarIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Bonus</span>
      </nuxt-link>
    </li>
    <li class="tw-text-manatee">
      <nuxt-link
        to="/message-reports"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <ChatErrorIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Message Reports</span>
      </nuxt-link>
    </li>
    <li class="tw-text-manatee">
      <nuxt-link
        to="/traffic-stats"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <ArrowToLeftIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Traffic Stats</span>
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BlocksIcon from '@/components/icon/BlocksIcon.vue'
import MessageIcon from '@/components/icon/MessageIcon.vue'
import NotificationIcon from '@/components/icon/NotificationIcon.vue'
import GroupIcon from '@/components/icon/GroupIcon.vue'
import FileThunderIcon from '@/components/icon/FileThunderIcon.vue'
import QuestionIcon from '@/components/icon/QuestionIcon.vue'
import ChatErrorIcon from '@/components/icon/ChatErrorIcon.vue'
import StarIcon from '@/components/icon/StarIcon.vue'
import ArrowToLeftIcon from '@/components/icon/TriangleErrorIcon.vue'

export default {
  components: {
    BlocksIcon,
    MessageIcon,
    NotificationIcon,
    GroupIcon,
    FileThunderIcon,
    QuestionIcon,
    ChatErrorIcon,
    StarIcon,
    ArrowToLeftIcon
  },

  props: {
    displayIconsOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      submenus: {
        logs: false
      }
    }
  },

  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews',
      isSidebarCollapsed: 'sidebar/isSidebarCollapsed'
    }),
    displayedSubmenus() {
      if (this.isSidebarCollapsed) {
        let collapsedSubmenus = {}
        for (const key in this.submenus) {
          collapsedSubmenus[key] = false
        }
        return collapsedSubmenus
      } else {
        return this.submenus
      }
    },
    faqViews() {
      return this.getFaqViews
    }
  },

  methods: {
    toggleSubmenu(submenu) {
      for (const key in this.submenus) {
        if (key !== submenu) {
          this.submenus[key] = false
        }
      }

      this.submenus[submenu] = !this.submenus[submenu]
      this.setSidebar(false)
    },
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews',
      setSidebar: 'sidebar/setSidebar'
    })
  }
}
</script>
