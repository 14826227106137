<template>
  <ul class="menu-list">
    <li>
      <nuxt-link
        to="/"
        active-class="tw-text-white"
        exact
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <BlocksIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Dashboard</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/messages"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <MessageIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Messages</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('create', 'Trigger')"
        to="/triggers/manage"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <TriggerIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Triggers</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Profile')"
        to="/profiles"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <GroupIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Profiles</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/news"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <div class="tw-flex tw-items-center tw-relative">
          <NotificationIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">News</span>
          <div
            v-if="newsCount > 0"
            class="tw-flex tw-absolute tw-h-3 tw-w-3 tw-top-0 tw-left-0"
          >
            <span
              class="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400  tw-opacity-50"
            ></span>
            <span
              class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-600"
            ></span>
          </div>
        </div>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/faqs"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <div class="tw-flex tw-items-center tw-relative">
          <QuestionIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">FAQs</span>
          <div
            v-if="faqViews.length"
            class="tw-flex tw-absolute tw-h-3 tw-w-3 tw-top-0 tw-left-0"
          >
            <span
              class="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400  tw-opacity-50"
            ></span>
            <span
              class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-600"
            ></span>
          </div>
        </div>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/bonus"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <StarIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Bonus</span>
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BlocksIcon from '@/components/icon/BlocksIcon.vue'
import MessageIcon from '@/components/icon/MessageIcon.vue'
import TriggerIcon from '@/components/icon/TriggerIcon.vue'
import GroupIcon from '@/components/icon/GroupIcon.vue'
import NotificationIcon from '@/components/icon/NotificationIcon.vue'
import QuestionIcon from '@/components/icon/QuestionIcon.vue'
import StarIcon from '@/components/icon/StarIcon.vue'

export default {
  components: {
    BlocksIcon,
    MessageIcon,
    TriggerIcon,
    GroupIcon,
    NotificationIcon,
    QuestionIcon,
    StarIcon
  },

  props: {
    displayIconsOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      boostedData: {},
      isOnMessagePage: this.$route.path === '/messages'
    }
  },
  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews'
    }),
    faqViews() {
      return this.getFaqViews
    },
    newsCount() {
      return this.$store.state.news.news_count
    }
  },
  watch: {
    $route(to, from) {
      this.isOnMessagePage = to.path === '/messages'
    }
  },
  mounted() {
    this.fetchBoostedStatus()
    this.fetchFaqViews()
  },
  methods: {
    async fetchBoostedStatus() {
      try {
        const response = await this.$axios.get('/operator/boosted-status')
        this.boostedData = {
          boosted: response.data.boosted,
          date: response.data.date
        }
      } catch (error) {
        console.error('Error fetching boosted status', error)
      }
    },
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews'
    })
  }
}
</script>
