const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['coach'] = require('../middleware/coach.js')
middleware['coach'] = middleware['coach'].default || middleware['coach']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['manager'] = require('../middleware/manager.js')
middleware['manager'] = middleware['manager'].default || middleware['manager']

export default middleware
