import FaqView from '@/models/FaqView'

export const state = () => ({
  faq_views: {
    data: []
  }
})

export const mutations = {
  SET_FAQ_VIEWS(state, faq_views) {
    state.faq_views.data = faq_views
  }
}

export const getters = {
  getFaqViews(state) {
    return state.faq_views.data
  }
}

export const actions = {
  async fetchFaqViews({ commit }) {
    await FaqView.get()
      .then(response => {
        commit('SET_FAQ_VIEWS', response.data)
      })
      .catch(error => {
        console.error('Error with fetching faq-views: ', error)
      })
  },
  async deleteFaqView({ dispatch }, faq_view) {
    faq_view
      .delete()
      .then(() => {
        dispatch('fetchFaqViews')
      })
      .catch(error => {
        console.error('Error with deleting faq-views: ', error)
      })
  }
}
