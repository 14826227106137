export default class OperatorPolicy {
  beforeAll(_, user) {
    if (!user) {
      return false
    }

    if (user && ['country_manager', 'admin'].includes(user.data.role)) {
      return true
    }
  }
  /**
   * Check if user can list team members
   *
   * @param {User} user
   */
  list(user) {
    return user.data.role === 'manager' || user.data.role === 'coach'
  }

  /**
   * Check if user can list manager stats
   *
   * @param {User} user
   */
  seeManagers(user) {
    return user.data.role === 'admin'
  }

  /**
   * Check if can create operator/coach/manager
   *
   * @param {User} user
   */
  create(user) {
    if (
      user &&
      ['country_manager', 'admin', 'manager', 'coach'].includes(user.data.role)
    ) {
      return true
    }
    return false
  }

  /**
   * Check if can create operator/coach/manager
   *
   * @param {User} user
   */
  deactivate(user) {
    if (user && ['manager', 'admin', 'coach'].includes(user.data.role)) {
      return true
    }
    return false
  }

  /**
   * Check if can create operator/coach/manager
   *
   * @param {User} user
   */
  delete(user) {
    if (user && ['admin'].includes(user.data.role)) {
      return true
    }
    return false
  }

  /**
   * Check if can create operator/coach/manager
   *
   * @param {User} user
   */
  seeRR(user) {
    if (
      user &&
      ['manager', 'country_manager', 'admin', 'coach'].includes(user.data.role)
    ) {
      return true
    }
    return false
  }

  /**
   * Check if can edit operator/coach/manager
   *
   * @param {User} user
   * @param {Operator} operator
   */
  edit(user, operator) {
    // Since the beforeAll hook already checked for admin and empty user we
    // don't have to do it here
    return user.data.id === operator.manager_id
  }

  /**
   * Check if can edit operator/coach/manager
   *
   * @param {User} user
   * @param {Operator} operator
   */
  appealInit(user, operator) {
    return (
      user.data.id === operator.coach_id || user.data.id === operator.manager_id
    )
  }

  appealApproved(user) {
    if (user && ['admin'].includes(user.data.role)) {
      return true
    }
    return false
  }
}
