<template>
  <b-dropdown
    position="is-bottom-left"
    append-to-body
    aria-role="list"
    trap-focus
    close-on-click
  >
    <template #trigger>
      <div
        class="tw-text-washed hover:tw-text-black tw-p-2 hover:tw-bg-gray-100 tw-rounded"
      >
        <div class="tw-relative">
          <BellIcon />
          <span
            v-if="notifications.data.length"
            class="tw-w-2 tw-h-2 tw-rounded-full tw-bg-red-600 tw-absolute tw-right-0 tw-top-0"
          ></span>
        </div>

        <!-- <span
          v-if="notifications.data.length"
          class="tw-ml-1 tw-text-red-600"
          >{{ notifications.data.length }}</span
        >
        <Bell v-else /> -->
      </div>
    </template>
    <div class="tw-flex tw-justify-start tw-ml-4 tw-mt-2">
      <span class="tw-text-gray-700 tw-font-semibold tw-text-lg">
        Notifications
      </span>
    </div>
    <b-dropdown-item v-if="!notifications.data.length" custom>
      <b-notification
        type="is-warning is-light"
        :closable="false"
        aria-close-label="Close notification"
        class="tw-px-32"
      >
        No new notifications.
      </b-notification>
    </b-dropdown-item>
    <b-dropdown-item
      v-for="notification in notifications.data"
      :key="notification.id"
      class="tw-px-6 tw-mt-1"
      @click="markAsRead(notification)"
    >
      <div class="media">
        <figure class="media-left tw-mt-1">
          <b-icon
            :icon="getAction(notification.data.type).icon"
            size="is-large"
            :type="getAction(notification.data.type).type"
          >
          </b-icon>
        </figure>
        <div class="media-content">
          <div class="content">
            <div class="tw-flex tw-items-center tw-justify-between">
              <div>
                <span class="tw-text-gray-600 tw-mr-24 tw-font-medium">{{
                  notification.data.type.toUpperCase() + '!'
                }}</span>
              </div>
              <span class="tw-text-washed">{{ notification.publishedAt }}</span>
            </div>
            <div class="tw-flex tw-justify-between">
              <p
                v-if="notification.data.message.length > 25"
                class="tw-mt-2 tw-mr-32"
                v-html="
                  $sanitize(
                    notification.data.message.substring(0, 25).concat('...')
                  )
                "
              ></p>
              <p
                v-else
                class="tw-mt-2 tw-mr-32"
                v-html="$sanitize(notification.data.message)"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </b-dropdown-item>

    <b-dropdown-item has-link @click="markAllAsRead">
      <nuxt-link
        to="/notifications"
        class="tw-flex tw-justify-center tw-items-center tw-w-full"
      >
        See All
      </nuxt-link>
    </b-dropdown-item>
  </b-dropdown>
  <!-- <section>
    <b-dropdown
      position="is-bottom-left"
      append-to-body
      aria-role="menu"
      trap-focus
      close-on-click
    >
      <template #trigger>
        <div
          class="tw-flex tw-items-center tw-text-washed hover:tw-text-black tw-leading-none"
        >
          <BellBadge v-if="notifications.data.length" fill-color="red" />
          <span
            v-if="notifications.data.length"
            class="tw-ml-1 tw-text-red-600"
            >{{ notifications.data.length }}</span
          >
          <Bell v-else />
        </div>
      </template>
      <div class="tw-flex tw-justify-start tw-ml-4 tw-mt-2">
        <span class="tw-text-gray-700 tw-font-semibold tw-text-lg">
          Notifications
        </span>
      </div>
      <b-dropdown-item v-if="!notifications.data.length" custom>
        <b-notification
          type="is-warning is-light"
          :closable="false"
          aria-close-label="Close notification"
          class="tw-px-32"
        >
          No new notifications.
        </b-notification>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="notification in notifications.data"
        :key="notification.id"
        class="tw-px-6 tw-mt-1"
        @click="markAsRead(notification)"
      >
        <div class="media">
          <figure class="media-left tw-mt-1">
            <b-icon
              :icon="getAction(notification.data.type).icon"
              size="is-large"
              :type="getAction(notification.data.type).type"
            >
            </b-icon>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="tw-flex tw-items-center tw-justify-between">
                <div>
                  <span class="tw-text-gray-600 tw-mr-24 tw-font-medium">{{
                    notification.data.type.toUpperCase() + '!'
                  }}</span>
                </div>
                <span class="tw-text-washed">{{
                  notification.publishedAt
                }}</span>
              </div>
              <div class="tw-flex tw-justify-between">
                <p
                  v-if="notification.data.message.length > 25"
                  class="tw-mt-2 tw-mr-32"
                  v-html="
                    $sanitize(
                      notification.data.message.substring(0, 25).concat('...')
                    )
                  "
                ></p>
                <p
                  v-else
                  class="tw-mt-2 tw-mr-32"
                  v-html="$sanitize(notification.data.message)"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </b-dropdown-item>

      <b-dropdown-item has-link @click="markAllAsRead">
        <nuxt-link
          to="/notifications"
          class="tw-flex tw-justify-center tw-items-center tw-w-full"
        >
          See All
        </nuxt-link>
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      v-if="isModalActive"
      width="1200px"
      :active.sync="isModalActive"
      :can-cancel="['escape', 'x']"
    >
      <div class="card tw-rounded-lg tw-max-h-[500px] tw-overflow-y-auto">
        <div class="card-header">
          <p class="card-header-title">Notification</p>
        </div>
        <div class="card-content">
          <article class=" tw-bg-blue-100 tw-rounded-xl tw-p-6">
            <div class="media">
              <figure class="media-left tw-mt-1">
                <b-icon
                  :icon="getAction(modalContent.data.type).icon"
                  size="is-large"
                  :type="getAction(modalContent.data.type).type"
                >
                </b-icon>
              </figure>
              <div class="media-content">
                <div class="content">
                  <div class="tw-flex tw-items-center tw-justify-between">
                    <div>
                      <span class="tw-text-gray-600 tw-font-medium">{{
                        modalContent.data.type.toUpperCase() + '!'
                      }}</span>
                      <strong>{{
                        getAction(modalContent.data.type).description
                      }}</strong>
                    </div>
                    <span class="tw-text-washed">{{
                      modalContent.publishedAt
                    }}</span>
                  </div>
                  <div class="tw-flex tw-justify-between">
                    <p
                      class="tw-mt-2 tw-mr-40 tw-break-all"
                      v-html="$sanitize(modalContent.data.message)"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="card-footer">
          <div class="card-footer-item">
            <b-button
              rounded
              type="is-info"
              @click.prevent="isModalActive = false"
              >Close</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </section> -->
</template>

<script>
import { mapState } from 'vuex'

import Notification from '@/models/Notification'
import BellIcon from '@/components/icon/BellIcon.vue'

export default {
  middleware: 'auth',
  components: {
    BellIcon
  },
  data() {
    return {
      notifications: {
        data: [],
        meta: {
          current_page: 1
        }
      },
      actions: {
        payRateDecrease: {
          string: 'pay rate decrease',
          description:
            'Your pay rate will be decreased for the following reasons',
          icon: 'arrow-down',
          type: 'is-danger'
        },
        payRateIncrease: {
          string: 'pay rate increase',
          description:
            'Congratulations! Thanks to your hard work and determination, your pay rate has been increased!',
          icon: 'arrow-up',
          type: 'is-success'
        },
        warning: {
          string: 'warning',
          description: 'Please pay close attention to this warning',
          icon: 'alert-circle',
          type: 'is-danger'
        },
        improvements: {
          string: 'improvements',
          description:
            'Please make sure to read these thoroughly and be sure to incorporate and follow all improvements accordingly',
          icon: 'alert-circle',
          type: 'is-warning'
        }
      },
      isModalActive: false,
      modalContent: {
        data: []
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user.data
    }),
    getAction() {
      return actionType => {
        for (let action in this.actions) {
          if (this.actions[action].string === actionType.toLowerCase()) {
            return this.actions[action]
          }
        }
      }
    }
  },
  mounted() {
    this.getNotifications()
  },
  methods: {
    async getNotifications(refresh = false) {
      if (refresh) {
        this.notifications.meta.current_page = 1
      }

      try {
        const notifications = await new Notification()
          .where('unread', true)
          .limit(5)
          .get()

        this.notifications = notifications
      } catch (error) {
        console.log(error)
      }
    },
    async markAsRead(notification) {
      try {
        await this.$axios.post(
          `/operator/notifications/read/${notification.id}`
        )
        this.getNotifications()
        this.openModal(notification)
      } catch (error) {
        console.log(error)
      }
    },
    async markAllAsRead() {
      try {
        await this.$axios.post('/operator/notifications/read-all')
        this.getNotifications()
      } catch (error) {
        console.log(error)
      }
    },
    openModal(notification) {
      this.modalContent = notification
      this.isModalActive = true
    }
  }
}
</script>
