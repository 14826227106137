export const state = () => ({
  global_timeout: null
})

export const mutations = {
  SET_TIMEOUT(state) {
    if (!state.global_timeout) {
      state.global_timeout = setTimeout(async () => {
        await this.$axios.put('operator/offline')

        await this.$axios.post('operator/offline-tracker', {
          action: 'od-offline-system'
        })

        clearTimeout(state.global_timeout)
      }, 5 * 1000 * 60)
    }
  },

  CLEAR_TIMEOUT(state) {
    clearTimeout(state.global_timeout)
    state.global_timeout = null
  }
}

export const actions = {
  setGlobalTimeout({ commit }) {
    commit('SET_TIMEOUT')
  },

  clearGlobalTimeout({ commit }) {
    commit('CLEAR_TIMEOUT')
  }
}
