<template>
  <b-button
    type="is-danger is-light"
    size="is-small"
    @click="operatorToOffline"
  >
    I'm Offline
  </b-button>
</template>
<script>
import OperatorOffline from '../utils/operator-offline.vue'

export default {
  methods: {
    async operatorToOffline() {
      if (!this.isOnMessagePage) {
        await this.$axios.put(`operator/offline`)
        this.trackAction()
        this.$buefy.snackbar.open({
          message: 'You are now offline',
          type: 'is-success',
          position: 'is-top'
        })
      }

      OperatorOffline.$emit('operator-offline')
    },
    async trackAction() {
      await this.$axios.post(`operator/offline-tracker`, {
        action: 'od-offline-clicked'
      })
    }
  }
}
</script>
