<template>
  <div v-if="isUserEarnable">
    <div class="tw-leading-tight tw-mr-6">
      <div class="tw-text-sm tw-flex tw-flex-col tw-text-left">
        <span class="tw-text-washed tw-text-xs">Messages sent today</span>
        <span class="tw-font-medium tw-text-black tw-tracking-tight">
          {{ earnings.messages_sent_today }}
        </span>
      </div>
    </div>
    <div class="tw-leading-tight">
      <div class="tw-text-sm tw-flex tw-flex-col tw-text-left">
        <span class="tw-text-washed tw-text-xs">Earnings today</span>
        <span class="tw-font-medium tw-text-black tw-tracking-tight">
          {{ earnings.currency }}{{ earnings.earnings_today | amount }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: state => state.auth.user?.data,
      earnings: state => state.earnings.earnings
    }),
    isUserEarnable() {
      return ['coach', 'operator'].indexOf(this.user?.role) > -1
    },
    _user() {
      return (this.$auth.user || {}).data
    },

    _showFixedWeeklyCard() {
      return this._user?.weekly_salary > 0
    }
  },
  mounted() {
    if (this.isUserEarnable) {
      this.getEarnings()
    }

    this.interval = setInterval(() => {}, 1000 * 60)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions({
      getEarnings: 'earnings/getEarnings'
    })
  }
}
</script>

<style></style>
