<template>
  <b-button
    tag="router-link"
    size="is-small"
    :to="to"
    icon-left="circle"
    :type="boosted ? 'is-success is-light' : 'is-danger is-light'"
  >
    {{ label }}
    <!-- <span :class="[boosted ? 'green-circle' : 'red-circle']"></span> -->
  </b-button>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: '/news'
    },
    label: {
      type: String,
      default: 'BOOST'
    },
    boosted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.boost-button {
  padding: 0 20px;
  border-radius: 5px;
}

.red-circle,
.green-circle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin: 3px 0px;
}

.red-circle {
  background-color: red;
}

.green-circle {
  background-color: green;
}
</style>
