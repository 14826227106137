<template>
  <div>
    <nav
      class="navbar tw-bg-body tw-shadow operator-nav is-fixed-top"
      :class="pageWidth"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="app-container container">
        <div class="navbar-brand tw-items-center is-hidden-desktop">
          <a class="navbar-item tw-text-xl tw-font-medium tw-py-6" href="/"
            >Operator Dashboard</a
          >
          <a
            role="button"
            class="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            onclick="document.querySelector('.navbar-menu').classList.toggle('is-active');"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div v-if="$auth.loggedIn" class="navbar-menu">
          <NavOperator v-if="$auth.user.data.role == 'operator'"></NavOperator>
          <NavManager v-if="$auth.user.data.role == 'manager'"></NavManager>
          <NavCoach v-if="$auth.user.data.role == 'coach'"></NavCoach>
          <NavCountryManager v-if="$auth.user.data.role == 'country_manager'">
          </NavCountryManager>
          <NavAdmin v-if="$auth.user.data.role == 'admin'"></NavAdmin>
          <div class="navbar-end">
            <OperatorEarning
              v-if="
                $auth.user.data.role == 'operator' ||
                  $auth.user.data.role == 'coach'
              "
              class="navbar-item tw-mr-14"
            />
            <div class="navbar-item tw-flex tw-items-center tw-content-center">
              <div>
                <NotificationsDropdown />

                <b-dropdown position="is-bottom-left" :triggers="['hover']">
                  <template #trigger>
                    <div
                      class="tw-text-washed hover:tw-text-black tw-leading-none tw-p-2 hover:tw-bg-gray-100 tw-rounded"
                      role="button"
                    >
                      <UserIcon />
                    </div>
                  </template>
                  <b-dropdown-item custom class="tw-p-0">
                    <div
                      class="tw-flex tw-flex-col tw-items-start tw-border-b tw-px-4 tw-py-2"
                    >
                      <div class="tw-font-medium tw-text-black">
                        {{ $auth.user.data.first_name }}
                      </div>
                      <div class="tw-text-washed tw-text-sm tw-text-left">
                        {{ $auth.user.data.email }}
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item has-link>
                    <nuxt-link to="/profile">
                      Profile
                    </nuxt-link>
                  </b-dropdown-item>
                  <b-dropdown-item has-link>
                    <a @click="logout">Logout</a>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <AdminStats
      v-if="
        $auth.loggedIn &&
          ($auth.user.data.role == 'manager' ||
            $auth.user.data.role == 'admin' ||
            $auth.user.data.role == 'coach')
      "
    ></AdminStats>
  </div>
</template>

<script>
import NavManager from '@/components/layout/main/nav-manager'
import NavOperator from '@/components/layout/main/nav-operator'
import NavCoach from '@/components/layout/main/nav-coach'
import NavAdmin from '@/components/layout/main/nav-admin'
import NavCountryManager from '@/components/layout/main/nav-country-manager'
import AdminStats from '@/components/layout/main/admin-stats'
import OperatorEarning from '@/components/utils/operator-earning'
import { mapMutations, mapGetters } from 'vuex'
import NotificationsDropdown from '@/components/operator/notifications-dropdown'
import UserIcon from '@/components/icon/UserIcon.vue'

export default {
  components: {
    NavManager,
    NavOperator,
    NavCoach,
    NavAdmin,
    NavCountryManager,
    AdminStats,
    OperatorEarning,
    NotificationsDropdown,
    UserIcon
  },
  head() {
    return {
      htmlAttrs: {
        class: 'tw-bg-body has-navbar-fixed-top'
      }
    }
  },
  computed: {
    ...mapGetters({ isSidebarCollapsed: 'sidebar/isSidebarCollapsed' }),
    pageWidth() {
      if (this.$auth.loggedIn && !this.isSidebarCollapsed) {
        return 'lg:tw-pl-65'
      } else {
        return 'lg:tw-pl-16' // or an empty string if you prefer no additional class
      }
    }
  },
  methods: {
    async logout() {
      await this.$auth.logout()
      location.replace('/')
      localStorage.removeItem('teamFilter')
      this.resetModal()
    },
    ...mapMutations('users', ['resetModal'])
  }
}
</script>

<style>
.operator-nav {
  background: #eee;
}

.is-active.navbar-item {
  color: #209cee !important;
  font-weight: 600;
}
</style>
