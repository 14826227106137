<template>
  <span :class="['svg-icon', iconClass, sizeClass]"
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <defs />
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <path
          d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
          fill="#000000"
          opacity="0.3"
        />
        <path
          d="M12.6706167,18.7881514 L15.9697449,13.8394592 C16.1995092,13.4948127 16.1063788,13.0291607 15.7617323,12.7993963 C15.6385316,12.7172626 15.4937759,12.673434 15.3457071,12.673434 L12.659208,12.673434 L12.659208,9.69999981 C12.659208,9.28578625 12.3234215,8.94999981 11.909208,8.94999981 C11.6584431,8.94999981 11.4242696,9.07532566 11.2851703,9.28397466 L7.98604212,14.2326669 C7.75627777,14.5773134 7.84940818,15.0429654 8.19405469,15.2727297 C8.31725533,15.3548635 8.4620111,15.398692 8.61007984,15.398692 L11.296579,15.398692 L11.296579,18.3721263 C11.296579,18.7863398 11.6323654,19.1221263 12.046579,19.1221263 C12.2973439,19.1221263 12.5315174,18.9968004 12.6706167,18.7881514 Z"
          fill="#000000"
          opacity="0.3"
        />
      </g></svg
    ><!--end::Svg Icon--></span
  >
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '24px'
    }
  },

  computed: {
    iconClass() {
      return `svg-icon-${this.type}`
    },
    sizeClass() {
      return `svg-icon-${this.size}`
    }
  }
}
</script>
