export default class ProfilePolicy {
  beforeAll(_, user) {
    if (!user) {
      return false
    }

    if (user.data.role === 'admin') {
      return true
    }
  }

  /**
   * Check if can list profiles
   *
   * @param {User} user
   */
  list(user) {
    return user.data.role === 'manager' || this.create(user)
  }

  /**
   * Can approve/reject submitted profiles
   */
  manage(user) {
    return user.data.role === 'manager'
  }
  /**
   * Check if can create profile
   *
   * @param {User} user
   */
  create(user) {
    return (
      user.data.profiles_enabled &&
      (user.data.role === 'coach' || user.data.role === 'operator')
    )
  }

  /**
   * Check if can edit profile
   *
   * @param {User} user
   * @param {Profile} profile
   */
  edit(user, profile) {
    return user.data.id === profile.created_by
  }

  /**
   * Check if can delete profile
   *
   * @param {User} user
   * @param {Profile} profile
   */
  delete(user, profile) {
    return user.data.role === 'admin'
  }
}
