<template>
  <div class="error-container">
    <nuxt />
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.error-container {
  background: hsl(357, 100%, 41%);
  color: #fff !important;
}
</style>
