<template>
  <b-button tag="router-link" to="/news" type="is-light" size="is-small">
    <div class="tw-flex tw-items-center tw-leading-none">
      <ClockIcon class="tw-mr-1" type="info" />
      <span class="tw-text-blue-600 tw-text-center">GMT-4</span>
      <span class="tw-font-medium tw-text-blue-600 tw-tracking-tight">
        {{ currentDatetime }}</span
      >
    </div>
  </b-button>
</template>

<script>
import ClockIcon from '@/components/icon/ClockIcon.vue'

export default {
  components: { ClockIcon },
  props: {
    currentDatetime: {
      type: String,
      default: ''
    }
  }
}
</script>
