import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3d88056e = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _3412b0c3 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _773baa18 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _35fc9347 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _ad8f3f10 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _7b65cd8f = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _23c6e5a2 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _4d17b399 = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _1fc664d0 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _5c658837 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _7936e9b2 = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _153a16ec = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _101a7223 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _2690ea51 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _05cc03da = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _81f4938a = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _4cc910fa = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _fc907cfc = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _728a14c5 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1000acee = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _71c340e4 = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _0e85f240 = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _23a4d08a = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _bcae3cc8 = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _7edba612 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _1500be95 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _70a0b543 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _2537de25 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _7364e473 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _41d573a6 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _9f2100e6 = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _4a887f7c = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _8205bc42 = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _059f105e = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _2409d1c7 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _ba6923de = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _3b44aa83 = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _deb946a4 = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _89b0c6bc = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _0c335361 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _7d46800c = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _cad44c40 = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _6e566a1a = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _bfce509c = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _8449445c = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _126a70d0 = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _bb597bc8 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _b5030b14 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _67c75d7b = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _973f57da = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _4e476b47 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _0e3e6bee = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _6d2cfbd4 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _c9304d96 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _f6677ca0 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _3b8c6e4e = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _a98a79c2 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _dee7d3e0 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _2bbb5173 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ai-reports",
    component: _3d88056e,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _3412b0c3,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _773baa18,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _35fc9347,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _ad8f3f10,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _7b65cd8f,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _23c6e5a2,
    name: "feedback-funnel"
  }, {
    path: "/ht-alerts",
    component: _4d17b399,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _1fc664d0,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _5c658837,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _7936e9b2,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _153a16ec,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _101a7223,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _2690ea51,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _05cc03da,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _81f4938a,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _4cc910fa,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _fc907cfc,
    name: "my-team"
  }, {
    path: "/news",
    component: _728a14c5,
    name: "news"
  }, {
    path: "/notifications",
    component: _1000acee,
    name: "notifications"
  }, {
    path: "/payment-updates",
    component: _71c340e4,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _0e85f240,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _23a4d08a,
    name: "profile"
  }, {
    path: "/profiles",
    component: _bcae3cc8,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _7edba612,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _1500be95,
    name: "settings"
  }, {
    path: "/stats",
    component: _70a0b543,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _2537de25,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _7364e473,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _41d573a6,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _9f2100e6,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _4a887f7c,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _8205bc42,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _059f105e,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _2409d1c7,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _ba6923de,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _3b44aa83,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _deb946a4,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _89b0c6bc,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _0c335361,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _7d46800c,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _cad44c40,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _6e566a1a,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _bfce509c,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _8449445c,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/activity-logs/:type?",
    component: _126a70d0,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _bb597bc8,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _b5030b14,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _67c75d7b,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _973f57da,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _4e476b47,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _0e3e6bee,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _6d2cfbd4,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _c9304d96,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _f6677ca0,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _3b8c6e4e,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _a98a79c2,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _dee7d3e0,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _2bbb5173,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
