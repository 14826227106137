export const state = () => ({
  modalVisible: false
})

export const mutations = {
  showModal(state) {
    state.modalVisible = true
  },
  hideModal(state) {
    state.modalVisible = false
    localStorage.setItem('userHasLoggedInBefore', true)
  },
  resetModal(state) {
    state.modalVisible = true
    localStorage.removeItem('userHasLoggedInBefore')
  }
}

export const actions = {
  checkUserLogin({ commit }) {
    setTimeout(() => {
      if (!localStorage.getItem('userHasLoggedInBefore')) {
        commit('showModal')
      }
    }, 2000)
  }
}
