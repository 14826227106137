<template>
  <div
    class="tw-bg-body tw-shadow tw-mt-6 lg:tw-mt-0"
    :class="pageWidth"
    role="navigation"
  >
    <div class="container app-container tw-min-h-20">
      <div class="tw-flex tw-items-center">
        <div
          v-if="$auth.user.data.country_id == 229"
          class="navbar-item tw-flex"
        >
          <img
            src="~/assets/images/united-kingdom-rounded.png"
            alt="UK"
            class="tw-mr-1 tw-w-4"
          />
          <span
            class="tw-font-medium tw-text-lg"
            :class="stats.uk > 99 ? 'has-text-error' : ''"
            >{{ stats.uk }}</span
          >
        </div>
        <div
          v-if="$auth.user.data.country_id == 229"
          class="navbar-item tw-flex"
        >
          <img
            src="~/assets/images/canada-rounded.png"
            class="tw-mr-1 tw-w-4"
            alt="CA"
          />
          <span
            :class="stats.ca > 99 ? 'has-text-error' : ''"
            class="tw-font-medium tw-text-lg"
            >{{ stats.ca }}</span
          >
        </div>
        <div
          v-if="$auth.user.data.country_id == 229"
          class="navbar-item tw-flex"
        >
          <img
            src="~/assets/images/united-states-rounded.png"
            class="tw-mr-1 tw-w-4"
            alt="US"
          />
          <span
            class="tw-font-medium tw-text-lg"
            :class="stats.us > 99 ? 'has-text-error' : ''"
            >{{ stats.us }}</span
          >
        </div>
        <div
          v-if="$auth.user.data.country_id == 229"
          class="navbar-item tw-flex"
        >
          <img
            src="~/assets/images/australia-rounded.png"
            class="tw-mr-1 tw-w-4"
            alt="AU"
          />
          <span
            class="tw-font-medium tw-text-lg"
            :class="stats.au > 99 ? 'has-text-error' : ''"
            >{{ stats.au }}</span
          >
        </div>
        <div
          v-if="$auth.user.data.country_id == 229"
          class="navbar-item tw-flex"
        >
          <img
            src="~/assets/images/new-zealand-rounded.png"
            class="tw-mr-1 tw-w-4"
            alt="NZ"
          />
          <span
            class="tw-font-medium tw-text-lg"
            :class="stats.nz > 99 ? 'has-text-error' : ''"
            >{{ stats.nz }}</span
          >
        </div>
        <div class="navbar-item tw-flex">
          <img
            src="~/assets/images/south-africa-rounded.png"
            class="tw-mr-1 tw-w-4"
            alt="ZA"
          />
          <span
            class="tw-font-medium tw-text-lg"
            :class="stats.za > 99 ? 'has-text-error' : ''"
            >{{ stats.za }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminStats',
  data() {
    return {
      stats: {
        uk: 0,
        us: 0,
        ca: 0
      }
    }
  },
  computed: {
    ...mapGetters({ isSidebarCollapsed: 'sidebar/isSidebarCollapsed' }),
    pageWidth() {
      if (this.$auth.loggedIn && !this.isSidebarCollapsed) {
        return 'lg:tw-pl-65'
      } else {
        return 'lg:tw-pl-16' // or an empty string if you prefer no additional class
      }
    }
  },
  created() {
    this.startTimer()
    this.fetch()
  },
  methods: {
    async fetch() {
      this.stats = (await this.$axios.get(`/operator/pending-messages`)).data
    },
    startTimer() {
      setInterval(() => {
        this.fetch()
      }, 500 * 60) //30secs
    }
  }
}
</script>

<style scoped>
.nav-stat-title {
  color: #eee;
}
.stats-nav {
  z-index: 1 !important;
}
</style>
