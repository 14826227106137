export default class InvoicePolicy {
  beforeAll(_, user) {
    if (user && ['country_manager', 'admin'].includes(user.data.role)) {
      return true
    } else {
      return false
    }
  }
  /**
   * Check if user can list invoices
   */
  list(user) {
    return true
  }
  /**
   * Check if user can manage invoices
   */
  manage(user) {
    return true
  }
}
