<template>
  <ul class="menu-list">
    <li>
      <nuxt-link
        to="/"
        active-class="tw-text-white"
        exact
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <BlocksIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Dashboard</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/messages"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <MessageIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Messages</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Trigger')"
        to="/triggers"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
        ><TriggerIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Triggers</span></nuxt-link
      >
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Profile')"
        to="/profiles"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
        ><GroupIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Profiles</span></nuxt-link
      >
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Operator')"
        to="/my-team"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
        ><GroupIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">My Team</span>
      </nuxt-link>
    </li>
    <li>
      <a
        class="has-dropdown tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center tw-justify-between"
        @click="toggleSubmenu('logs')"
      >
        <div class="tw-flex tw-items-center">
          <FileThunderIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">Logs</span>
        </div>

        <b-icon icon="chevron-down" size="is-small"></b-icon>
      </a>
      <ul v-show="displayedSubmenus.logs">
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/journal-edits"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Journal Edits
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/lock-logs"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Lock Logs
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/pay-rate"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Pay Rate
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/payment-updates"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Payment Updates
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/removed-accounts"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Removed Accounts
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/added-accounts"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Added Accounts
          </nuxt-link>
        </li>
      </ul>
    </li>
    <li>
      <nuxt-link
        to="/news"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <div class="tw-flex tw-items-center tw-relative">
          <NotificationIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">News</span>
          <div
            v-if="newsCount > 0"
            class="tw-flex tw-absolute tw-h-3 tw-w-3 tw-top-0 tw-left-0"
          >
            <span
              class="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400  tw-opacity-50"
            ></span>
            <span
              class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-600"
            ></span>
          </div>
        </div>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Profile')"
        to="/feedback-funnel"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
        ><FilterIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Feedback Funnel</span></nuxt-link
      >
    </li>
    <li>
      <nuxt-link
        to="/pl-stats"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <LineChartIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">PL Stats</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/faqs"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <div class="tw-flex tw-items-center tw-relative">
          <QuestionIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">FAQs</span>
          <div
            v-if="faqViews.length"
            class="tw-flex tw-absolute tw-h-3 tw-w-3 tw-top-0 tw-left-0"
          >
            <span
              class="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400  tw-opacity-50"
            ></span>
            <span
              class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-600"
            ></span>
          </div>
        </div>
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BlocksIcon from '@/components/icon/BlocksIcon.vue'
import MessageIcon from '@/components/icon/MessageIcon.vue'
import TriggerIcon from '@/components/icon/TriggerIcon.vue'
import GroupIcon from '@/components/icon/GroupIcon.vue'
import FileThunderIcon from '@/components/icon/FileThunderIcon.vue'
import FilterIcon from '@/components/icon/FilterIcon.vue'
import LineChartIcon from '@/components/icon/LineChartIcon.vue'
import QuestionIcon from '@/components/icon/QuestionIcon.vue'
import NotificationIcon from '@/components/icon/NotificationIcon.vue'

export default {
  components: {
    BlocksIcon,
    MessageIcon,
    TriggerIcon,
    GroupIcon,
    FileThunderIcon,
    FilterIcon,
    LineChartIcon,
    QuestionIcon,
    NotificationIcon
  },

  props: {
    displayIconsOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      submenus: {
        logs: false
      }
    }
  },

  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews',
      isSidebarCollapsed: 'sidebar/isSidebarCollapsed'
    }),
    faqViews() {
      return this.getFaqViews
    },
    newsCount() {
      return this.$store.state.news.news_count
    },
    displayedSubmenus() {
      if (this.isSidebarCollapsed) {
        let collapsedSubmenus = {}
        for (const key in this.submenus) {
          collapsedSubmenus[key] = false
        }
        return collapsedSubmenus
      } else {
        return this.submenus
      }
    }
  },
  mounted() {
    this.fetchFaqViews()
  },

  methods: {
    toggleSubmenu(submenu) {
      for (const key in this.submenus) {
        if (key !== submenu) {
          this.submenus[key] = false
        }
      }

      this.submenus[submenu] = !this.submenus[submenu]
      this.setSidebar(false)
    },
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews',
      setSidebar: 'sidebar/setSidebar'
    })
  }
}
</script>
