<template>
  <ul class="menu-list">
    <li>
      <nuxt-link
        to="/"
        active-class="tw-text-white"
        exact
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <BlocksIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Dashboard</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Operator')"
        to="/my-team"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <GroupIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">My Team</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Invoice')"
        to="/invoices"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <FileIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Invoices</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/news"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <div class="tw-flex tw-items-center tw-relative">
          <NotificationIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">News</span>
          <div
            v-if="newsCount > 0"
            class="tw-flex tw-absolute tw-h-3 tw-w-3 tw-top-0 tw-left-0"
          >
            <span
              class="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400  tw-opacity-50"
            ></span>
            <span
              class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-600"
            ></span>
          </div>
        </div>
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import BlocksIcon from '@/components/icon/BlocksIcon.vue'
import GroupIcon from '@/components/icon/GroupIcon.vue'
import FileIcon from '@/components/icon/FileIcon.vue'
import NotificationIcon from '@/components/icon/NotificationIcon.vue'

export default {
  components: {
    BlocksIcon,
    GroupIcon,
    FileIcon,
    NotificationIcon
  },

  props: {
    displayIconsOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    newsCount() {
      return this.$store.state.news.news_count
    }
  }
}
</script>
