import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

Vue.filter('dateFormat', function(
  date,
  format = 'YYYY-MM-DD',
  tz = 'Europe/London'
) {
  return dayjs(
    dayjs(date, 'Europe/London')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
  )
    .tz('Europe/London')
    .format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('amountNew', function(val, decimals, asInt) {
  if (!val) {
    return 0
  }

  val = val / 100

  return parseFloat(Math.round(val * 100) / 100).toFixed(
    decimals !== undefined ? decimals : 2
  )
})

Vue.filter('amount', function(val, decimals, asInt) {
  if (!val) {
    return val
  }

  if (asInt) {
    val = val / 100
  }

  return parseFloat(Math.round(val * 100) / 100).toFixed(
    decimals !== undefined ? decimals : 2
  )
})

Vue.filter('absolute', function(val) {
  if (!val) {
    return 0
  }
  return Math.abs(val)
})

Vue.filter('ceil', function(val) {
  if (!val) {
    return 0
  }
  return Math.ceil(val)
})

Vue.filter('round', function(val) {
  if (!val) {
    return 0
  }
  return Math.round(+val)
})

Vue.filter('decimals', function(val) {
  if (!val) {
    return 0
  }
  return parseFloat(val).toFixed(2)
})

Vue.filter('percentage', function(val) {
  if (!val) {
    return 0
  }
  val = val * 100
  return Math.round(val)
})

Vue.filter('convertSerial', function(val) {
  if (!val) {
    return 'transparent'
  }

  let decryptedHex = ''
  let hexSegments = val.match(/.{1,4}/g)

  for (let i = 0; i < hexSegments.length; i++) {
    decryptedHex += hexSegments[i].charAt(0)
  }

  return (
    '#' +
    decryptedHex
      .split('')
      .reverse()
      .join('')
  )
})
