<template>
  <div v-if="$auth.user" class="navbar-start">
    <nuxt-link
      to="/messages"
      active-class="is-active"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Messages
    </nuxt-link>
    <nuxt-link
      v-if="$can('list', 'Trigger')"
      to="/triggers"
      active-class="is-active"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Triggers
    </nuxt-link>
    <nuxt-link
      v-if="$can('list', 'Profile')"
      to="/profiles"
      active-class="is-active"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Profiles
    </nuxt-link>
    <nuxt-link
      v-if="$can('list', 'Operator')"
      to="/my-team"
      active-class="is-active"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      My Team
    </nuxt-link>
    <nuxt-link
      v-if="$can('list', 'Invoice')"
      to="/invoices"
      active-class="is-active"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Invoices
    </nuxt-link>

    <div class="navbar-item tw-text-left is-hidden-desktop">
      <b-dropdown position="is-bottom-left">
        <template #trigger>
          <span
            class="tw-font-medium tw-text-washed hover:tw-text-black"
            role="button"
          >
            Logs
            <i class="icon-arrow-down tw-text-sm"></i>
          </span>
        </template>

        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/lock-logs"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Lock Logs
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/pay-rate"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Pay Rate
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/payment-updates"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Payment Updates
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/removed-accounts"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Removed Accounts
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/added-accounts"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Added Accounts
          </nuxt-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="navbar-item tw-text-left is-hidden-desktop">
      <b-dropdown position="is-bottom-left">
        <template #trigger>
          <span
            class="tw-font-medium tw-text-washed hover:tw-text-black"
            role="button"
          >
            Duplicate
            <i class="icon-arrow-down tw-text-sm"></i>
          </span>
        </template>

        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/39"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Dupe CA
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/231"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Dupe UK2
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 232"
            to="/profile-duplicates/232"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Dupe ZA
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/230"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Dupe US
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/234"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Dupe AU
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/235"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Dupe NZ
          </nuxt-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="navbar-item tw-text-left is-hidden-desktop">
      <b-dropdown position="is-bottom-left">
        <template #trigger>
          <span
            class="tw-font-medium tw-text-washed hover:tw-text-black"
            role="button"
          >
            Resources
            <i class="icon-arrow-down tw-text-sm"></i>
          </span>
        </template>

        <b-dropdown-item has-link>
          <nuxt-link
            to="/news"
            active-class="is-active"
            class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
          >
            News
            <b-tag v-if="newsCount > 0" class="tw-ml-2" type="is-info">{{
              newsCount
            }}</b-tag>
          </nuxt-link>
        </b-dropdown-item>

        <b-dropdown-item has-link>
          <nuxt-link
            to="/manuals"
            active-class="is-active"
            class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Manuals
          </nuxt-link>
        </b-dropdown-item>

        <b-dropdown-item has-link>
          <nuxt-link
            to="/rejection-categories"
            active-class="is-active"
            class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Rejection Categories
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            to="/faqs"
            active-class="is-active"
            class="navbar-item tw-text-left is-hidden-desktop tw-flex tw-items-center tw-font-medium tw-text-washed hover:tw-text-black"
          >
            FAQs
            <span v-if="faqViews.length" class="tw-ml-1">
              <i class="mdi mdi-bell mdi-18px" style="color: #fc406c"></i>
            </span>
          </nuxt-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="navbar-item tw-text-left is-hidden-desktop">
      <b-dropdown position="is-bottom-left">
        <template #trigger>
          <span
            class="tw-font-medium tw-text-washed hover:tw-text-black"
            role="button"
          >
            Stats
            <i class="icon-arrow-down tw-text-sm"></i>
          </span>
        </template>

        <b-dropdown-item has-link>
          <nuxt-link
            to="/feedback-funnel"
            active-class="is-active"
            class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Feedback Funnel
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            to="/pl-stats"
            active-class="is-active"
            class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
          >
            PL Stats
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            to="/wink-replies"
            active-class="is-active"
            class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Wink Replies Stats
          </nuxt-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      class="navbar-item tw-text-left is-hidden-desktop tw-flex tw-items-center tw-content-center"
    >
      <div class="buttons">
        <OfflineButton />
        <DateButton :current-datetime="boostedData.date" />
        <BoostButton :boosted="boostedData.boosted" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OfflineButton from '../../operator/operator-offline-button.vue'
import BoostButton from './boosted-hours'
import DateButton from './date-time.vue'

export default {
  components: {
    OfflineButton,
    BoostButton,
    DateButton
  },
  data() {
    return {
      boostedData: {}
    }
  },
  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews'
    }),
    faqViews() {
      return this.getFaqViews
    },
    newsCount() {
      return this.$store.state.news.news_count
    }
  },
  mounted() {
    this.fetchBoostedStatus()
    this.fetchFaqViews()
  },
  methods: {
    async fetchBoostedStatus() {
      try {
        const response = await this.$axios.get('/operator/boosted-status')
        this.boostedData = {
          boosted: response.data.boosted,
          date: response.data.date
        }
      } catch (error) {
        console.error('Error fetching boosted status', error)
      }
    },
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews'
    })
  }
}
</script>
