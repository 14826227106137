import Vue from 'vue'
import Acl from 'vue-browser-acl'
import ProfilePolicy from '@/policies/ProfilePolicy'
import Profile from '@/models/Profile'
import TriggerPolicy from '@/policies/TriggerPolicy'
import Trigger from '@/models/Trigger'
import Operator from '@/models/Operator'
import OperatorPolicy from '@/policies/OperatorPolicy'
import Invoice from '@/models/Invoice'
import InvoicePolicy from '@/policies/InvoicePolicy'
import News from '@/models/News'
import NewsPolicy from '@/policies/NewsPolicy'

export default ({ app, store }) => {
  const user = () => store.$auth.user

  Vue.use(Acl, user, acl => {
    acl.register(Profile, 'Profile')
    acl.register(Trigger, 'Trigger')
    acl.register(Operator, 'Operator')
    acl.register(Invoice, 'Invoice')
    acl.policy(ProfilePolicy, Profile)
    acl.policy(TriggerPolicy, Trigger)
    acl.policy(OperatorPolicy, Operator)
    acl.policy(InvoicePolicy, Invoice)
    acl.policy(NewsPolicy, News)
  })
}
