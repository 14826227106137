import Model from './Model'

export default class Trigger extends Model {
  resource() {
    return 'operator/triggers'
  }

  get triggerType() {
    return this.is_adult ? 'adult' : 'mainstream'
  }

  get isMine() {
    return window.Store.$auth.user.data.id === this.created_by_id
  }
}
