<template>
  <div class="tw-bg-gray-100">
    <nuxt />
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.login-container {
  background: hsl(171, 100%, 41%);
}
</style>
