<template>
  <span :class="['svg-icon', iconClass, sizeClass]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      version="1.1"
      :width="size"
      :height="size"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon
          points="12 2 22 20 2 20"
          fill="#000000"
          stroke="#FFFFFF"
          stroke-width="2"
        />
        <text
          x="12"
          y="16"
          text-anchor="middle"
          fill="#FFFFFF"
          font-size="12"
          font-family="Arial"
          dy=".3em"
        >
          !
        </text>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '24px'
    }
  },

  computed: {
    iconClass() {
      return `svg-icon-${this.type}`
    },
    sizeClass() {
      return `svg-icon-${this.size}`
    }
  }
}
</script>
