export const state = () => ({
  earnings: {
    currency: '',
    earnings_today: '',
    earnings_this_week: '',
    earnings_last_week: '',
    messages_sent_today: ''
  }
})

export const mutations = {
  setEarnings(state, earnings) {
    state.earnings = earnings
  }
}

export const actions = {
  async getEarnings({ commit }) {
    const { data } = await this.$axios.get('operator/earnings')
    commit('setEarnings', data.data)
  },
  incrementEarnings({ rootState, commit, state }) {
    const messageRate = rootState.auth.user.data.message_cents
    const rate = messageRate ? messageRate / 100 : 0

    if (rate) {
      const earnings = {
        ...state.earnings,
        earnings_today: parseFloat(state.earnings.earnings_today) + rate,
        earnings_this_week:
          parseFloat(state.earnings.earnings_this_week) + rate,
        messages_sent_today: parseFloat(state.earnings.messages_sent_today) + 1
      }

      commit('setEarnings', earnings)
    }
  }
}
