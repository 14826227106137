import Vue from 'vue'
import infiniteScroll from 'vue-infinite-scroll'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY || 'random',
  releaseStage: process.env.NODE_ENV,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production', 'staging']
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

Vue.use(infiniteScroll)
