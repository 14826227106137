import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Model from './Model'

dayjs.extend(utc)

export default class Notification extends Model {
  resource() {
    return 'operator/notifications'
  }

  get publishedAt() {
    return dayjs.utc(this.created_at).format('H:m, DD MMM, YYYY')
  }
}
