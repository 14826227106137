import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Model from './Model'

dayjs.extend(utc)

export default class News extends Model {
  resource() {
    return 'operator/news'
  }

  get createdAt() {
    return dayjs.utc(this.created_at).format('H:m, DD MMM, YYYY')
  }
}
