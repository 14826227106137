export default class NewsPolicy {
  beforeAll(_, user) {
    if (!user) {
      return false
    }

    if (user.data.role === 'admin') {
      return true
    }
  }

  /**
   * Check if user can create news
   */
  create(user) {
    return ['country_manager', 'admin', 'manager'].includes(user.data.role)
  }
}
