export default function({ $axios, store, error, app }) {
  // let nuxtError = error
  $axios.onError(error => {
    if (error && error.response && error.response.status === 422) {
      store.dispatch('validation/setErrors', error.response.data.errors)
    }

    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('auth._token.local')
      document.cookie =
        'auth._token.local=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      if (app.$auth.$state.loggedIn) {
        app.$auth.logout()
      }
    }

    if (error && error.response && error.response.data) {
      if (error.response.data.message === 'account_locked') {
        // return nuxtError({
        //   statusCode: 490,
        //   message: error.response.data.message
        // })
      } else if (error.response.data.error === 'Unauthorized') {
        // return nuxtError({
        //   statusCode: 401,
        //   message: 'Invalid Credentials'
        // })
      }
      store.dispatch('validation/failedLogin', error.response.status)
    }
    return Promise.reject(error)
  })

  $axios.onResponse(() => {
    // alert('test')
  })

  $axios.onRequest(() => {
    store.dispatch('validation/clearErrors')
  })
}
