<template>
  <div>
    <Header></Header>
    <Sidebar v-if="$auth.loggedIn" />

    <div :class="pageWidth">
      <nuxt class="tw-text-sm" />
    </div>

    <div v-for="article in news.data" :key="article.id">
      <b-modal v-if="article" :active.sync="modalVisible" :can-cancel="[]">
        <div class="card tw-rounded-lg tw-max-h-[500px] tw-overflow-y-auto">
          <div class="card-header">
            <p class="card-header-title">Latest News</p>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <div class="content">
                  <h2 class="tw-mt-4 tw-text-2xl tw-text-black tw-mb-4">
                    {{ article.title }}
                  </h2>
                  <div
                    class="tw-text-washed"
                    v-html="$sanitize(article.body)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="card-footer-item">
              <b-button rounded type="is-info" @click.prevent="hideModal"
                >Close</b-button
              >
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Header from '@/components/layout/main/header.vue'
import Sidebar from '@/components/layout/main/sidebar.vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Header,
    Sidebar
  },
  data() {
    return {
      news: {
        data: []
      }
    }
  },
  computed: {
    ...mapState('users', ['modalVisible']),
    ...mapGetters({ isSidebarCollapsed: 'sidebar/isSidebarCollapsed' }),
    pageWidth() {
      if (this.$auth.loggedIn && !this.isSidebarCollapsed) {
        return 'lg:tw-pl-65'
      } else {
        return 'lg:tw-pl-16' // or an empty string if you prefer no additional class
      }
    }
  },
  watch: {
    modalVisible(isOpen) {
      if (isOpen) {
        this.getPopupNews()
      }
    }
  },
  mounted() {
    this.getNewsCount()
    this.checkUserLogin()
  },
  methods: {
    getNewsCount() {
      this.$axios.get('/operator/news/count').then(({ data }) => {
        this.$store.commit('news/setNewsCount', data.data.news_count)
      })
    },
    ...mapMutations('users', ['hideModal']),
    ...mapActions('users', ['checkUserLogin']),
    async getPopupNews() {
      await this.$axios
        .get(`/operator/popup`)
        .then(data => {
          this.news = data
        })
        .catch(error => {
          this.hideModal()
          if (error.response && error.response.status === 404) {
            console.error('no news to popup')
          } else {
            console.error(error)
          }
        })
    }
  }
}
</script>
