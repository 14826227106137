<template>
  <div class="app-wrapper bg-white h-100">
    <div class="app-main">
      <div class="divider border-2 border-primary bg-primary"></div>
      <div class="app-content p-0">
        <div class="app-content--inner d-flex align-items-center">
          <div class="flex-grow-1 w-100 d-flex align-items-center">
            <div class="bg-composed-wrapper--content py-5">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6 pr-0 d-flex align-items-center">
                    <div class="pl-5">
                      <div>
                        <h1 v-if="error.statusCode === 503">
                          Sorry, we are currently upgrading the server. We will
                          be back shortly.
                        </h1>
                        <h1 v-else-if="error.statusCode === 490">
                          We detected suspicious behaviour. Your account is
                          temporarily locked. Please contact your manager.
                        </h1>
                        <h1 v-else>An error occurred</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['error'],
  layout: 'error-layout'
}
</script>
