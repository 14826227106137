import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Model from './Model'

dayjs.extend(utc)

export default class Message extends Model {
  resource() {
    return 'messages'
  }

  get isFromTrigger() {
    return !!this.trigger_id
  }

  get isFromInternal() {
    return this.from.is_internal
  }

  get sentAt() {
    return dayjs.utc(this.created_at).format('H:m, DD MMM, YYYY')
  }

  get isReportable() {
    return this.sent_by_operator_id && !this.report_count
  }

  get fromImage() {
    if (this.from.approved_primary_photo_thumb) {
      return this.from.approved_primary_photo_thumb
    }

    if (this.from.primary_photo) {
      return this.from.primary_photo.url
    }

    return this.from.gender == 'female'
      ? '/images/avatars/female.jpg'
      : '/images/avatars/male.jpg'
  }
}
