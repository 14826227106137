<template>
  <div class="tw-ml-5 navbar-start tw-flex tw-justify-between tw-w-full ">
    <div class="w-1/2 navbar-start tw-flex tw-justify-start text-lg">
      <!-- <nuxt-link
        to="/triggers"
        active-class="is-active"
        class="navbar-item tw-text-left is-hidden-desktop tw-mr-10"
        >Triggers</nuxt-link
      > -->
      <nuxt-link
        v-if="$can('list', 'Operator')"
        to="/my-team"
        active-class="is-active"
        class="navbar-item tw-text-left is-hidden-desktop tw-mr-10"
      >
        My Team
      </nuxt-link>
      <nuxt-link
        v-if="$can('list', 'Invoice')"
        to="/invoices"
        active-class="is-active"
        class="navbar-item tw-text-left is-hidden-desktop tw-mr-10"
      >
        Invoices
      </nuxt-link>
      <nuxt-link
        to="/news"
        active-class="is-active"
        class="navbar-item tw-text-left is-hidden-desktop tw-mr-10"
      >
        News
        <b-tag v-if="newsCount > 0" class="tw-ml-2" type="is-info">{{
          newsCount
        }}</b-tag>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    newsCount() {
      return this.$store.state.news.news_count
    }
  }
}
</script>
