import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Model from './Model'

dayjs.extend(utc)

export default class Invoice extends Model {
  resource() {
    return 'operator/invoices'
  }

  get from() {
    return dayjs.utc(this.period_from).format('MMM D, YYYY')
  }

  get to() {
    return dayjs.utc(this.period_to).format('MMM D, YYYY')
  }
}
