export default {
  state: {
    sidebarCollapsed: false
  },

  mutations: {
    toggleSidebar(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed
    },
    setSidebar(state, payload) {
      state.sidebarCollapsed = payload
    }
  },

  actions: {
    toggleSidebar({ commit }, payload) {
      commit('toggleSidebar', payload)
    },
    setSidebar({ commit }, payload) {
      commit('setSidebar', payload)
    }
  },

  getters: {
    isSidebarCollapsed(state) {
      return state.sidebarCollapsed
    }
  }
}
