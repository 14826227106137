<template>
  <div v-if="$auth.user" class="navbar-start">
    <nuxt-link
      to="/messages"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Messages
    </nuxt-link>
    <nuxt-link
      v-if="$can('list', 'Trigger')"
      to="/triggers"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
      >Triggers</nuxt-link
    >
    <nuxt-link
      v-if="$can('list', 'Profile')"
      to="/profiles"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
      >Profiles</nuxt-link
    >
    <nuxt-link
      v-if="$can('list', 'Operator')"
      to="/my-team"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
      >My Team</nuxt-link
    >
    <div class="navbar-item is-hidden-desktop">
      <b-dropdown position="is-bottom-left">
        <template #trigger>
          <span
            class="tw-text-left tw-font-medium tw-text-washed hover:tw-text-black"
            role="button"
          >
            Logs
            <i class="icon-arrow-down tw-text-sm"></i>
          </span>
        </template>

        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/lock-logs"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Lock Logs
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/pay-rate"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Pay Rate
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/payment-updates"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Payment Updates
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/removed-accounts"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Removed Accounts
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/added-accounts"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Added Accounts
          </nuxt-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <nuxt-link
      to="/news"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      News
      <b-tag v-if="newsCount > 0" class="tw-ml-2" type="is-info">{{
        newsCount
      }}</b-tag>
    </nuxt-link>
    <nuxt-link
      v-if="$can('list', 'Profile')"
      to="/feedback-funnel"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
      >Feedback Funnel</nuxt-link
    >
    <nuxt-link
      to="/pl-stats"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      PL Stats
    </nuxt-link>
    <nuxt-link
      to="/faqs"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      FAQs
      <b-icon
        v-if="faqViews.length"
        icon="bell"
        class="tw-ml-1"
        style="color: #fc406c"
      ></b-icon>
    </nuxt-link>
    <div
      class="navbar-item is-hidden-desktop tw-flex tw-items-center tw-content-center"
    >
      <div class="buttons">
        <OfflineButton />
        <DateButton :current-datetime="boostedData.date" />
        <BoostButton :boosted="boostedData.boosted" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OfflineButton from '../../operator/operator-offline-button.vue'
import BoostButton from './boosted-hours'
import DateButton from './date-time.vue'

export default {
  components: {
    OfflineButton,
    BoostButton,
    DateButton
  },
  data() {
    return {
      boostedData: {}
    }
  },
  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews'
    }),
    faqViews() {
      return this.getFaqViews
    },
    newsCount() {
      return this.$store.state.news.news_count
    }
  },
  mounted() {
    this.fetchBoostedStatus()
    this.fetchFaqViews()
  },
  methods: {
    async fetchBoostedStatus() {
      try {
        const response = await this.$axios.get('/operator/boosted-status')
        this.boostedData = {
          boosted: response.data.boosted,
          date: response.data.date
        }
      } catch (error) {
        console.error('Error fetching boosted status', error)
      }
    },
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews'
    })
  }
}
</script>
