import Model from './Model'
import Message from './Message'

export default class Operator extends Model {
  resource() {
    return 'operator/operators'
  }

  messages() {
    return this.hasMany(Message)
  }
}
