<template>
  <div v-if="$auth.user" class="navbar-start">
    <nuxt-link
      to="/messages"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Messages
    </nuxt-link>
    <nuxt-link
      v-if="$can('create', 'Trigger')"
      to="/triggers/manage"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Triggers
    </nuxt-link>
    <nuxt-link
      v-if="$can('list', 'Profile')"
      to="/profiles"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Profiles
    </nuxt-link>
    <nuxt-link
      to="/news"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      News
      <b-tag
        v-if="newsCount > 0"
        class="tw-ml-2 tw-rounded-full tw-bg-red-600 tw-text-white"
        >{{ newsCount }}</b-tag
      >
    </nuxt-link>

    <nuxt-link
      to="/faqs"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      FAQs
      <b-icon
        v-if="faqViews.length"
        icon="bell"
        class="tw-ml-1"
        style="color: #fc406c"
      ></b-icon>
    </nuxt-link>
    <nuxt-link
      to="/bonus"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-bg-green-400 tw-text-white"
    >
      Bonus
    </nuxt-link>
    <div class="navbar-item tw-text-left">
      <div class="buttons">
        <OfflineButton />
        <DateButton :current-datetime="boostedData.date" />
        <BoostButton :boosted="boostedData.boosted" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OfflineButton from '../../operator/operator-offline-button.vue'
import BoostButton from './boosted-hours'
import DateButton from './date-time.vue'

export default {
  components: {
    OfflineButton,
    BoostButton,
    DateButton
  },
  data() {
    return {
      boostedData: {},
      isOnMessagePage: this.$route.path === '/messages'
    }
  },
  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews'
    }),
    faqViews() {
      return this.getFaqViews
    },
    newsCount() {
      return this.$store.state.news.news_count
    }
  },
  watch: {
    $route(to, from) {
      this.isOnMessagePage = to.path === '/messages'
    }
  },
  mounted() {
    this.fetchBoostedStatus()
    this.fetchFaqViews()
  },
  methods: {
    async fetchBoostedStatus() {
      try {
        const response = await this.$axios.get('/operator/boosted-status')
        this.boostedData = {
          boosted: response.data.boosted,
          date: response.data.date
        }
      } catch (error) {
        console.error('Error fetching boosted status', error)
      }
    },
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews'
    })
  }
}
</script>
